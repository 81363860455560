.cont{
    background-color: var(--gray-color);
    color: var(--font-color); 
     
}
.pointer{
    cursor: pointer  
}
.textOverFlow{
    overflow: hidden;
   display: -webkit-box;
   -webkit-line-clamp: 2; /* number of lines to show */
           line-clamp: 2; 
   -webkit-box-orient: vertical;
  }