.authentication-container {
  display: flex;
  flex-direction: column;
 
width: 40%;
height: 100%;
 
}


