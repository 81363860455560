.expioreCard {
  height: 250px;
  position: relative;
  box-shadow: 2px 2px 10px 0px #0000003d;
  border-radius: 10px;
  background-color: var(--gray-color);
  color: var(--font-color);
}
.expioreCard:hover {
  border: 3px solid blue;
  border-radius: 19px;
  background-color: var( --background-color-hover);
}
.exploreText {
  text-align: center;
  position: absolute;
  font-weight: bold;
  font-size: 23px;
  top: 40px;
  color: var(--font-color);
}

