
#foot {
  text-decoration: none;
  font-size: 20px;
  padding-top: 5px;
}
.footer-container.p-4 {
  background: #888 url("https://res.cloudinary.com/dh0kvk4g5/image/upload/v1694462433/covers%20and%20logos/RDX-MY23_Homepage-Hero_XL_w3dpnn.avif") no-repeat center/cover ;
  height: auto;
  width: 100%;
  background-blend-mode: multiply;
}
#foot:hover {
  padding: 5px;
  background-color: rgb(255, 145, 0);
  border-radius: 5px;
  transition: 0.5s;
}
