.autoNews{
  height: 300px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
  border-radius: 18px;
  box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
  transition-duration: 15;
  transition-delay: 0.2s;
  transition-property: margin-left margin-top;
  transition-timing-function: linear;


}
.imgBackGroundOne{
    background:#888 url("https://res.cloudinary.com/dh0kvk4g5/image/upload/v1694468940/%D9%88%D9%83%D8%A7%D9%84%D8%A9-%D8%A3%D9%85%D8%B1%D9%8A%D9%83%D9%8A%D8%A9-%D8%A7%D9%86%D8%AA%D8%B9%D8%A7%D8%B4-%D9%83%D8%A8%D9%8A%D8%B1-%D9%81%D9%8A-%D8%B3%D9%88%D9%82-%D8%A7%D9%84%D8%B3%D9%8A%D8%A7%D8%B1%D8%A7%D8%AA-%D8%A8%D9%80-%D8%A7%D9%84%D8%B3%D8%B9%D9%88%D8%AF%D9%8A%D8%A9-3_s9caao.jpg")no-repeat center/cover;
 background-blend-mode: multiply;
    transition: all .5s;
}
.imgBackGroundTwo{
    background:#888 url("https://res.cloudinary.com/dh0kvk4g5/image/upload/v1694462154/covers%20and%20logos/WhatsApp_Image_2023-09-11_at_22.54.36_mplovp.jpg")no-repeat center/cover;
    background-blend-mode: multiply;
  transition: all .5s;

}
.imgBackGroundOne:hover{
  transform: scale(1.05);
  
}
.imgBackGroundTwo:hover{
  transform: scale(1.05);

}
/* .autoNews:hover {
  height: 310px;
  transition: all 1s;
} */
.textCard {
  position: absolute;
  color: white;
  font-weight: bold;
  font-size: 45px;
  right: 50px;
  top: 75px;
}

.spanClass{
    display: flex;
    justify-content: space-between;
    font-size: 20px;
    font-weight: bold;
    color: var(--font-color);
}
.articlesCard{
  box-shadow: 4px 4px 20px 0px #0000003d;
  border-radius: 15px;
  height: 370px;
  background-color: var(--gray-color);
  color: var(--font-color);
}
.parentImg{
  overflow: hidden;
}
.articlesCard-img{
  transition: all .5s;
}
.articlesCard-img:hover{
  transform: scale(1.5);
  cursor: pointer;
 
}

.articlesCard-taxt{
  font-weight: bold;
  font-size: 20px;
}
.articlesCard-taxt:hover{
  color: blue;
  cursor: pointer;
}
