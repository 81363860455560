.userSide {
  padding: 20px;
  color: var(--font-color);
}
.userEdit {
  background-color: var(--card-color);
  /* padding: 20px; */
  box-shadow: 2px 2px 10px 0px #0000003d;
  border-radius: 5px;
}
.itemsHover:hover {
  background-color: blue;
  color: #fff;
}
.cardUser {
  text-align: center;
  border: 2px solid blue;
  border-radius: 5px;
  background-color: var(--card-color);
  height: 200px;
}
.formRow {
  box-shadow: 2px 2px 5px 0px #0000003d;
  background-color: var(--card-color);
  color: var(--font-color);
  border-radius: 5px;
}
@media (max-width: 991px) {
  .userSideHidden {
    display: none;
  }
}
