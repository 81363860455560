.brandCard {
  position: relative;
  width: 200px;
  height: 180px;
  box-shadow: 2px 2px 10px 0px #0000003d;
  border-radius: 15px;
  background-color: var(--gray-color);
  color: var(--font-color);
}
.textBrandCard {
  top: 33%;
  right: 35%;
  text-align: center;
  position: absolute;
  font-weight: bold;
  font-size: 18px;
}
.brandCard:hover {
  border: 3px solid blue;
  border-radius: 19px;
  background-color: var(--background-color-hover);
}
